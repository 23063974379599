import React from 'react';

// Import helpers
import { ENVIRONMENT_TYPE } from 'helpers';

const useMediaQuery = (query) => {
	if (process.env.REACT_APP_NODE_ENV !== ENVIRONMENT_TYPE.PRODUCTION) {
		if (typeof query !== 'string') {
			console.error('Invalid query argument'); // eslint-disable-line no-console
		}
	}

	const [match, setMatch] = React.useState(false);

	React.useEffect(() => {
		let active = true;

		const isMatchMediaSupported =
			typeof window !== undefined &&
			typeof window?.matchMedia !== undefined;

		if (!isMatchMediaSupported) {
			return undefined;
		}

		const updateMatch = () => {
			const queryList = window.matchMedia(query);
			if (active) {
				setMatch(queryList.matches);
			}
		};

		updateMatch();
		window.addEventListener('resize', updateMatch);

		return () => {
			active = false;
			window.removeEventListener('resize', updateMatch);
		};
	}, [query]);

	return match;
};

export default useMediaQuery;
