import React from 'react';
import { arrayOf, bool, node, oneOfType, string } from 'prop-types';

// Import helpers
import { HEADER_BAR_HEIGHT } from 'helpers';

const Section = React.forwardRef(
	(
		{
			id = '/',
			className = '',
			isAutoHeight = false,
			isMinHeight = false,
			children,
			...rest
		},
		ref
	) => {
		const style = isMinHeight
			? {
					height: 'auto',
					minHeight: `calc(100vh - ${HEADER_BAR_HEIGHT}px)`
			  }
			: {
					height:
						!isAutoHeight && `calc(100vh - ${HEADER_BAR_HEIGHT}px)`
			  };

		return (
			<section
				id={id}
				className={`relative ${className}`}
				style={style}
				ref={ref}
				{...rest}
			>
				{children}
			</section>
		);
	}
);

Section.propTypes = {
	id: string,
	className: string,
	isAutoHeight: bool,
	isMinHeight: bool,
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default Section;
