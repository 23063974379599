import React from 'react';
import { number, oneOf } from 'prop-types';

// Import icons
import HeaderLogoIconPrimary from 'assets/icons/header-logo.svg';
import HeaderLogoIconLight from 'assets/icons/logo-light.svg';

// Import helpers
import { VARIANTS } from './helpers';

const Logo = ({ variant = VARIANTS.PRIMARY, size = 60 }) => {
	const remSize = size && (size / 4)?.toFixed(0);
	const className = `h-${remSize} w-${remSize}`;

	return variant === VARIANTS.PRIMARY ? (
		<HeaderLogoIconPrimary className={className} />
	) : (
		<HeaderLogoIconLight className={className} />
	);
};

Logo.propTypes = {
	variant: oneOf(Object.values(VARIANTS)),
	size: number
};

export default Logo;
