import React from 'react';
import { oneOf, string } from 'prop-types';

import AppStoreIcon from 'assets/icons/appstore.svg';
import GooglePlayIcon from 'assets/icons/googleplay.svg';

import { VARIANTS, SIZES, getSize } from './helpers';
import { MEDIA_QUERY_SIZE } from 'helpers/styles';
import { useMediaQuery } from 'components/utilities';

const { APPLE, ANDROID } = VARIANTS;

const Downloader = ({
	variant = VARIANTS.APPLE,
	link = '/',
	className = ''
}) => {
	const matchMd = useMediaQuery(`(min-width: ${MEDIA_QUERY_SIZE.md}px)`);

	const downloaderSize = matchMd ? SIZES.SMALL : SIZES.EXTRA_SMALL;

	const sizeStyles = getSize(downloaderSize);

	const renderItem = () => {
		switch (variant) {
			case APPLE:
				return <AppStoreIcon style={{ ...sizeStyles }} />;
			case ANDROID:
				return <GooglePlayIcon style={{ ...sizeStyles }} />;
			default:
				return <AppStoreIcon style={{ ...sizeStyles }} />;
		}
	};
	return (
		<div className={className}>
			<a href={link} target="_blank" rel="noreferrer">
				{renderItem()}
			</a>
		</div>
	);
};

Downloader.propTypes = {
	variant: oneOf(Object.values(VARIANTS)),
	className: string,
	link: string
};

export default Downloader;
