import React from 'react';
import { func, bool } from 'prop-types';
import { Transition } from 'react-transition-group';

// Import styles
import './styles.scss';

// Import icons
import CloseIcon from 'assets/icons/close.svg';

// Import helpers
import { transitionStyles, defaultStyle } from './helpers';

// Import components
import NavLinks from '../NavLinks';

const Drawer = ({ onClick, open }) => {
	return (
		<Transition unmountOnExit in={open} timeout={300}>
			{(state) => (
				<div
					style={{
						...defaultStyle,
						...transitionStyles[state]
					}}
					className={`menu shadow fixed top-0 right-0 h-full bg-gray-background`}
				>
					<div className="cursor-pointer">
						<CloseIcon className="close-icon" onClick={onClick} />
					</div>
					<NavLinks onClick={onClick} />
				</div>
			)}
		</Transition>
	);
};

Drawer.propTypes = {
	onClick: func.isRequired,
	open: bool.isRequired
};

export default Drawer;
