import React from 'react';
import { node, string } from 'prop-types';

const Typography = ({ className = '', children }) => {
	return <p className={`text-base ${className}`}>{children}</p>;
};

Typography.propTypes = {
	children: node.isRequired,
	className: string
};

export default Typography;
