export const defaultStyle = {
	transition: `right ${300}ms ease-in-out`,
	right: -320
};

export const transitionStyles = {
	entering: { right: 0 },
	entered: { right: 0 },
	exiting: { right: -320 },
	exited: { right: -320 }
};
