import React from 'react';
import { func } from 'prop-types';

const ToggleRPC = ({ children }) => {
	const [on, setOn] = React.useState(false);

	const handleToggle = React.useCallback(() => setOn((prev) => !prev), []);
	const handleSetOn = React.useCallback((value) => setOn(value), []);

	return children({ on, toggle: handleToggle, setOn: handleSetOn });
};

ToggleRPC.propTypes = {
	children: func.isRequired
};

export default ToggleRPC;
