import React from 'react';

// Import icons
import EuropeCurrencyIcon from 'assets/icons/eu_icon.svg';
import NfzIcon from 'assets/icons/nfz-icon.svg';
import EuropeFlagIcon from 'assets/icons/eu_flag.svg';

const Logos = () => {
	return (
		<div
			className={`flex justify-evenly items-center 
				sm:width-full 
				lg:width-auto`}
		>
			<div>
				<EuropeCurrencyIcon />
			</div>
			<div className={`px-4`}>
				<NfzIcon />
			</div>
			<div>
				<EuropeFlagIcon />
			</div>
		</div>
	);
};

export default Logos;
