import React from 'react';
import { string } from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Image = ({ fileName, alt = '', ...rest }) => {
	const { allImageSharp } = useStaticQuery(graphql`
		query MyQuery {
			allImageSharp {
				edges {
					node {
						fluid {
							...GatsbyImageSharpFluid
							originalName
						}
					}
				}
			}
		}
	`);

	const fluid = allImageSharp.edges.find(
		({ node: n }) => n.fluid.originalName === fileName
	)?.node?.fluid;

	return <Img fluid={fluid} alt={alt} {...rest} />;
};

Image.propTypes = {
	fileName: string.isRequired,
	alt: string
};

export default Image;
