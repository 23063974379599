import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { firebaseApp } from 'firebase-app';

import { STORAGE_PATHS } from './helpers';

firebaseApp();
const firebaseStorage = getStorage();

export class FirebaseStorage {
	static getDeclarationOfAvailability = () => {
		this.#downloadFromStorage(
			STORAGE_PATHS.DECLARATIONS.DECLARATION_OF_AVAILABILITY,
			this.#openFileInNewTab
		);
	};

	static getPrivacyPolicyFile = () => {
		this.#downloadFromStorage(
			STORAGE_PATHS.PRIVACY_POLICY,
			this.#openFileInNewTab
		);
	};

	static #downloadFromStorage = (path, callback) =>
		getDownloadURL(ref(firebaseStorage, path))
			.then((data) => {
				return callback(data);
			})
			.catch((error) => {
				return error;
			});

	static #openFileInNewTab = (url) => window.open(url, '_blank');
}
