import React from 'react';

// Import icons
import BurgerMenuIcon from 'assets/icons/menu.svg';

// Import components
import { Backdrop } from 'components/elements';
import Drawer from './Drawer';

// Import utilities
import { ToggleRPC } from 'components/utilities';

const SideDrawer = () => {
	return (
		<ToggleRPC>
			{({ on, toggle, setOn }) => (
				<>
					<div className="text-right cursor-pointer">
						<BurgerMenuIcon onClick={toggle} />
					</div>

					<Backdrop open={on} onClick={() => setOn(false)}>
						<Drawer open={on} onClick={() => setOn(false)} />
					</Backdrop>
				</>
			)}
		</ToggleRPC>
	);
};

export default SideDrawer;
