import React from 'react';

// Import icons
import PozPlusIcon from 'assets/icons/poz_plus.svg';

// Import styles
import './styles.scss';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { LIST_ITEMS } from './helpers';

// Import components
import { Logos } from './components';
import { Typography } from 'components/elements';

const Footer = () => {
	const { t } = useTranslations();

	const renderItem = ({ className = '', label, href, onClick }) => (
		<li
			key={label}
			className={`${className} text-center text-tiny 
							sm:text-left 
							xl:text-lg`}
		>
			<a
				href={href}
				onClick={onClick}
				target="_blank"
				rel="noreferrer"
				style={{ cursor: 'pointer' }}
			>
				{t(label)}
			</a>
		</li>
	);

	return (
		<footer
			className={`container mx-auto px-5 py-10 text-sm bg-gray-background root 
					lg:flex 
					xl:px-0`}
		>
			<div
				className={`flex flex-col items-center flex-1 
						sm:flex-row sm:justify-evenly 
						lg:width-full`}
			>
				<ul
					className={`list-wrapper pb-5 flex justify-evenly w-full 
						sm:flex-col sm:justify-between sm:items-center sm:pb-0 sm:flex-1 
						lg:items-start`}
				>
					{LIST_ITEMS.map(renderItem)}
				</ul>
				<div
					className={`flex-1 flex justify-center width-full 
						lg:justify-end lg:pr-8`}
				>
					<PozPlusIcon />
				</div>
			</div>

			<div
				className={`flex flex-col items-center 
					lg:width-full`}
			>
				<Typography
					className={`typography text-center py-5 text-superSmall
								sm:text-sm sm:py-10
								lg:text-tiny lg:py-0 lg:pb-5`}
				>
					{t('footer.materials')}
				</Typography>
				<Logos />
			</div>
		</footer>
	);
};

export default Footer;
