// Import translations
import * as translations from 'translations';

// Import helpers
import { LANGUAGES, getNestedObject } from 'helpers';

const useTranslations = () => {
	const t = (key) => {
		const translationObject = getNestedObject(translations, key);

		return translationObject ? translationObject[LANGUAGES.PL] : '';
	};
	return { t };
};

export default useTranslations;
