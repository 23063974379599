import React from 'react';
import { string } from 'prop-types';

const Button = ({ className = '', children = '' }) => {
	return (
		<button
			className={`focus:outline-none active:bg-primary 
            hover:bg-primaryDark hover:shadow-lg transition-all duration-200 
            h-14 py-3 px-5 bg-primary text-white text-lg rounded-lg ${className}`}
		>
			{children}
		</button>
	);
};

Button.propTypes = {
	className: string,
	children: string
};

export default Button;
