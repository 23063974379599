export const BREAK_POINTS = {
	DEFAULT: 'default',
	SM: 'sm',
	MD: 'md',
	LG: 'lg',
	XL: 'xl',
	DOUBLE_XL: 'doubleXl'
};

const { SM, MD, LG, XL, DOUBLE_XL } = BREAK_POINTS;
export const MEDIA_QUERY_SIZE = {
	[SM]: 640,
	[MD]: 768,
	[LG]: 1024,
	[XL]: 1280,
	[DOUBLE_XL]: 1536,
	HEIGHT: {
		[SM]: 475
	}
};
