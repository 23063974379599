export const VARIANTS = {
	APPLE: 'apple',
	ANDROID: 'android'
};

export const SIZES = {
	EXTRA_SMALL: 'extraSmall',
	SMALL: 'small',
	MEDIUM: 'medium',
	LARGE: 'large'
};

/**
 * get icon size based on value of passed size prop
 * @param {number} size size of download icon ;
 * @returns {object} icon dimensions
 */
export const getSize = (size) => {
	let height = 63;

	if (size === SIZES.EXTRA_SMALL) {
		height = 35;
	} else if (size === SIZES.SMALL) {
		height = 45;
	} else if (size === SIZES.LARGE) {
		height = 100;
	}

	return {
		height,
		width: height * 3.47
	};
};
