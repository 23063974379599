import { HEADER_HREF } from 'helpers';

const { START, VIDEO, ABOUT, FUNCTIONS, DOWNLOAD } = HEADER_HREF;

export const HEADER = [
	{ href: `#${START}`, label: 'header.start' },
	{ href: `#${VIDEO}`, label: 'header.video' },
	{ href: `#${ABOUT}`, label: 'header.about' },
	{ href: `#${FUNCTIONS}`, label: 'header.functions' },
	{ href: `#${DOWNLOAD}`, label: 'header.download' }
];
