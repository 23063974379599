import React from 'react';
import ReactDOM from 'react-dom';

import { oneOfType, arrayOf, node, func, bool } from 'prop-types';

// Import styles
import './styles.scss';

const Backdrop = ({ onClick = () => {}, open = false, children }) => {
	React.useEffect(() => {
		if (open) {
			document.body.style.overflow = 'hidden';
		}
		return () => {
			document.body.style.overflowY = 'scroll';
		};
	}, [open]);

	return (
		typeof document !== 'undefined' &&
		ReactDOM.createPortal(
			<>
				{open && (
					<div
						onClick={onClick}
						className={`backdrop fixed inset-0 w-screen h-screen z-50`}
						style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
					/>
				)}
				{children}
			</>,
			document.getElementById('portal')
		)
	);
};

Backdrop.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired,
	open: bool,
	onClick: func
};

export default Backdrop;
