/**
 *
 * @param {object} object
 * @param {string} key
 * @returns {object}
 */
export const getNestedObject = (object, key) => {
	if (!key || !object) {
		return null;
	}

	const keys = key.split('.');
	/*eslint-disable*/
	const nestedObject = keys.reduce((acc, next) => {
		if (acc) {
			acc = acc[next];
		} else {
			acc = object[next];
		}
		return acc;
	}, null);
	/*eslint-enable*/

	return nestedObject;
};
